import AccoladesBox from "./AccoladesBox";
import "./homeNewChanges.scss";
import { AccoladesData } from "./AccoladesData";
import { AccoladesDataCarousel1 } from "./AccoladesData";
import { AccoladesDataCarousel2 } from "./AccoladesData";
import { useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const AccoladesAndRecognitions = () => {
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });
  const theme = useTheme();

  const isMobile = useMediaQuery("(min-width: 0px) and (max-width: 767px)", {
    defaultMatches: false, // Set defaultMatches to false
  });
  return (
    <>
      <div id="accolades">
        <div className="main">
          <div className="heading-main">Accolades and Recognitions</div>

          {!isIpad && !isMobile && (
            <div className="accolades-center">
              <div className="accolades-container">
              {AccoladesData.map((data) => (
                <AccoladesBox
                  key={data.id}
                  title={data.heading}
                  description={data.description}
                  image={data.image}
                />
              ))}
            </div>
            </div>
          )}

          {!isMobile && isIpad && (
            <Carousel
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              // showDots={isMobile ? false : true}
            >
              <div className="box">
                {AccoladesDataCarousel1.map((data) => (
                  <AccoladesBox
                    key={data.id}
                    title={data.heading}
                    description={data.description}
                    image={data.image}
                  />
                ))}
              </div>
              <div className="box">
                {AccoladesDataCarousel2.map((data) => (
                  <AccoladesBox
                    key={data.id}
                    title={data.heading}
                    description={data.description}
                    image={data.image}
                  />
                ))}
              </div>
            </Carousel>
          )}
          {isMobile && !isIpad && (
            <Carousel
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={1500}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              showDots={false}
            >
              {AccoladesData.map((data) => (
                <AccoladesBox
                  key={data.id}
                  title={data.heading}
                  description={data.description}
                  image={data.image}
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
};

export default AccoladesAndRecognitions;
