import React from "react";
import { useMediaQuery } from "@mui/material/";
import AnshumanCeo from "../../assets/img/anshuman_ceo.png";
import DwarikaCoo from "../../assets/img/dwarika_coo.png";
import AnilVpAnalytics from "../../assets/img/anil-kumar-vp.jpg";
import AvinashVpBusiness from "../../assets/img/avinash_vp_business.png";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const filteredData = [
  {
    id: 1,
    image: AnshumanCeo,
    name: "Anshuman Bhar",
    role: "Co-Founder and CEO",
    content:
      "Anshuman comes from a strategic consulting and technology background with 12+ years of professional experience where he supported CXOs of large to mid-size organizations in several strategic projects ranging from enterprise-grade analytics implementation to corporate planning projects. Before founding Aays Analytics, he has extensive experience in implementing strategic projects for C-suites and other key stakeholders. He is also a mentor &amp; member of the selection panel of Endeavor, an amazing community dedicated to helping startups &amp; entrepreneurs. He has an MBA from IIM Indore and a B.Tech (EE) from IIT(ISM), Dhanbad.",
  },
  {
    id: 2,
    image: DwarikaCoo,
    name: "Dwarika Patro",
    role: "Co-Founder and COO",
    content:
      "Dwarika is a technology strategist with over 12 years of experience in the professional services industry helping blue chip and mid-market clients to make right technical investment decisions and building technical capabilities to enable them to take the digital transformation journey. He has a bachelor’s degree in engineering from IIT-Dhanbad and an MBA from Imperial College, London.",
  },
  {
    id: 3,
    name: "Avinash Sidha",
    role: "VP - Business Development & Client Delivery",
    image: AvinashVpBusiness,
    content:
      "With over 11 years of professional experience and solid knowledge of managing and operating businesses across multiple verticals, Avinash is an exceptional asset to the Aays Analytics. Avinash has a techno-functional background where he has helped companies build technologically advanced solutions. He has successfully delivered 30 plus product implementations with over $2m budgets and is highly skilled in delivering results in high-pressure environments, working cross-functionally with financial, compliance, and operational leaders. Avinash has an MBA from Imperial College London and has worked with many start-ups in implementing growth strategy roadmaps, building brand equity and creating multiple revenue streams.",
  },

  {
    id: 4,
    name: "Anil Kumar",
    role: "VP - Analytics",
    image: AnilVpAnalytics,
    content:
      "Anil is a seasoned professional with over 12+ years of diverse experience in solutioning and consulting, majorly in the automotive domain. He is someone who loves solving problems and is passionate about driving outcomes and creating impact by leveraging analytics and predictive modelling. He has worked with CXOs to enable both organic and inorganic growth and helps businesses maximize their potential with insights generated from data analytics and statistical modelling. He was instrumental in one of his past organization's successful business turnarounds and has been enabling data driven complex digital transformation programs. He is an MBA from AIM, Philippines and a BE Mechanical from ITM Gurgaon.",
  },
];

function Section4() {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <div className="about-section4 padding-both">
      <div className="header-text">Meet Our Leadership Team</div>

      <div className="grid-box">
        <Carousel
          className="customer-success-main"
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          infinite={true}
          slidesToSlide={1}
          swipeable={true}
          draggable={false}
          showDots={isMobile ? false : true}
        >
          {filteredData.map((data, index) => (
            <div className="grid-item" key={index}>
              <div className="image-div-container">
                <img
                  loading="lazy"
                  className="image-div"
                  src={data.image}
                  alt={data.name}
                />
              </div>

              {!isMobile && (
                <div className="grid-item-content">
                  <div className="grid-name-content">
                    <div className="grid-item-name">{data.name}</div>
                    <div className="grid-item-role">{data.role}</div>
                  </div>
                  <p className="content">{data.content}</p>
                </div>
              )}

              {isMobile && (
                <div className="mobile-item">
                  <div className="item-name">{data.name}</div>
                  <div className="item-role">{data.role}</div>
                </div>
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Section4;
