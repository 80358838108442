import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box } from "@mui/material";
import { Drawer, useMediaQuery, useTheme } from "@mui/material/";
import logo from "../assets/img/logo.png";
import styled from "@emotion/styled";
import $ from "jquery";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import "./navbarnew.scss";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as ArrowIcon } from "../assets/img/Arrow 1.svg";

const highlight = (elementId) => {
  const element = document.getElementById(elementId);

  if (element) {
    if (element.classList.contains("highlight1")) {
      element.classList.remove("highlight1");
    } else {
      element.classList.add("highlight1");
    }
  }
};

const removeHighlight = (elementId) => {
  const element = document.getElementById(elementId);

  if (element) {
    if (element.classList.contains("highlight1")) {
      element.classList.remove("highlight1");
    }
  }
};

const AppBarWrapper = styled(AppBar)({
  paddingTop: "10px",
});

const Navbar = () => {
  const [isMenuActive, setMenuActive] = useState(false);
  const [solution, setSolution] = useState(false);
  const [solutionMobile, setSolutionMobile] = useState(false);
  const [resources, setResources] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [functional, setFunctional] = useState(false);
  const [capabilities, setCapabilities] = useState(false);
  const [applications, setApplicatinos] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [subDrawerOpen, setSubDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const showSubMenu = (children) => {
    const subMenu = children.querySelector(".submenu");
    subMenu.classList.add("is-active");
    subMenu.style.animation = "slideLeft 0.35s ease forwards";
    const menuTitle =
      children.querySelector("i").parentNode.childNodes[0].textContent;
    document.querySelector(".menu-title").textContent = menuTitle;
    document.querySelector(".menu-header").classList.add("is-active");
  };

  const hideSubMenu = () => {
    const subMenu = document.querySelector(".submenu.is-active");
    if (subMenu) {
      subMenu.style.animation = "slideRight 0.35s ease forwards";
      setTimeout(() => {
        subMenu.classList.remove("is-active");
      }, 300);
      document.querySelector(".menu-title").textContent = "";
      document.querySelector(".menu-header").classList.remove("is-active");
    }
  };

  const toggleSubMenu = (e) => {
    if (!document.querySelector(".menu.is-active")) {
      return;
    }
    if (e.target.closest(".menu-dropdown")) {
      const children = e.target.closest(".menu-dropdown");
      showSubMenu(children);
    }
  };

  useEffect(() => {
    $(document).ready(function () {
      var scroll_start = 0;
      var startchange = $("#homePage");
      var offset = startchange.offset();
      if (startchange.length) {
        $(document).scroll(function () {
          scroll_start = $(this).scrollTop();
          var scrollHeight = $(document).height();
          var scrollPosition = $(window).height() + scroll_start;

          if (scroll_start > offset.top) {
            $("#header").removeClass("header");
            $("#header").addClass("nav-header-add");
            $(".scroll-btn-down").css("display", "block");
            $(".logo").css("paddingTop", "10px");
          } else {
            $("#header").addClass("header");
            $("#header").removeClass("nav-header-add");
            $(".scroll-btn-down").css("display", "none");
          }

          if (scrollPosition >= scrollHeight) {
            // Trigger an alert when the user reaches the bottom
            $(".scroll-btn").css("display", "none");
          } else {
            $(".scroll-btn").css("display", "block");
          }
        });
      }
    });
  });

  const MenuList = [
    {
      Menu: "Home",
      path: "/",
    },
    {
      Menu: "Solutions",
      path: "",
      subMenu: [
        {
          Menu: "By Industries",
          path: "",
          subMenu: [
            {
              Menu: "Consumer Packaged Goods",
              path: "/ai-solutions-cpg",
              name: "Consumer-Packaged",
            },
            {
              Menu: "Food & Beverage",
              path: "/ai-solutions-for-food-and-beverage-companies",
              name: "Food-Beverage",
            },
            {
              Menu: "Retail",
              path: "/ai-solutions-retail",
              name: "Retail",
            },
            {
              Menu: "Manufacturing",
              path: "/ai-and-analytics-in-manufacturing",
              name: "Manufacturing",
            },
            {
              Menu: "Automotive",
              path: "/ai-and-analytics-in-automotive-industry",
              name: "Automotive",
            },
          ],
        },
        {
          Menu: "By Functions",
          subMenu: [
            {
              Menu: "Supply Chain & Procurement",
              path: "/supply-chain-analytics",
              name: "Supply-Chain-Procurement",
            },
            {
              Menu: " Commercial & Finance",
              path: "/ai-and-finance-analytics",
              name: "Commercial",
            },
          ],
        },
        {
          Menu: "By Capabilities",
          subMenu: [
            {
              Menu: "Data Engineering",
              path: "/ai-and-data-engineering",
              name: "Data-Engineering",
            },
            {
              Menu: " AI Innovation",
              path: "/ai-innovations-enterprise-solutions",
              name: "AI-Innovation",
            },
            // {
            //   Menu: "Visualisation",
            //   path: "",
            //   name: "Visualisation",
            // },
          ],
        },
      ],
    },
    {
      Menu: "Applications",
      path: "",
      subMenu: [
        {
          Menu: "Gen-AI Insights ",
          path: "",

          subMenu: [
            {
              Menu: "Aays Decision Intelligence (AaDi)",
              path: "/aays-decision-intelligence-aadi",
              name: "aadi",
            },
          ],
        },
      ],
    },

    {
      Menu: "Resources",
      path: "",
      subMenu: [
        {
          Menu: "Case Studies",
          path: "/customer-success-stories",
          name: "Case-Studies",
        },
        {
          Menu: "Blogs",
          path: "/Blog",
          name: "Blog",
        },
        {
          Menu: "Newsroom",
          path: "/newsroom",
          name: "Newsroom",
        },
      ],
    },

    {
      Menu: "About",
      path: "/about",
    },
    {
      Menu: "Career",
      path: "/career",
    },
  ];

  const mobileSolutionList = [
    {
      Menu: "By Industries",
      path: "",
      name: "industries",

      subMenu: [
        {
          Menu: "Consumer Packaged Goods",
          path: "/ai-solutions-cpg",
          name: "Consumer-Packaged",
        },
        {
          Menu: "Food & Beverage",
          path: "/ai-solutions-for-food-and-beverage-companies",
          name: "Food-Beverage",
        },
        {
          Menu: "Retail",
          path: "/ai-solutions-retail",
          name: "Retail",
        },
        {
          Menu: "Manufacturing",
          path: "/ai-and-analytics-in-manufacturing",
          name: "Manufacturing",
        },
        {
          Menu: "Automotive",
          path: "/ai-and-analytics-in-automotive-industry",
          name: "Automotive",
        },
      ],
    },
    {
      Menu: "By Functions",
      name: "functions",
      subMenu: [
        {
          Menu: "Supply Chain & Procurement",
          path: "/supply-chain-analytics",
          name: "Supply-Chain-Procurement",
        },
        {
          Menu: " Commercial & Finance",
          path: "/ai-and-finance-analytics",
          name: "Commercial",
        },
        // {
        //   Menu: "Operations",
        //   path: "",
        //   name: "Operations",
        // },
      ],
    },
    {
      Menu: "By Capabilities",
      name: "capabilities",
      subMenu: [
        {
          Menu: "Data Engineering",
          path: "/ai-and-data-engineering",
          name: "Data-Engineering",
        },
        {
          Menu: " AI Innovation",
          path: "/ai-innovations-enterprise-solutions",
          name: "AI-Innovation",
        },
        // {
        //   Menu: "Visualisation",
        //   path: "",
        //   name: "Visualisation",
        // },
      ],
    },
  ];

  const mobileResorceList = [
    {
      Menu: "Case Studies",
      path: "/customer-success-stories",
      name: "Case-Studies",
    },
    {
      Menu: "Blogs",
      path: "/Blog",
      name: "Blog",
    },
    {
      Menu: "Newsroom",
      path: "/newsroom",
      name: "Newsroom",
    },
  ];

  const mobileApplicationsList = [
    {
      Menu: "AaDi",
      path: "/aays-decision-intelligence-aadi",
      name: "Applications",
    },
  ];

  const handleSubDrawerClose = () => {
    setSolution(false);
    setResources(false);
    setSubDrawerOpen(false);
  };

  const renderSubMenu = (subMenu) => {
    if (!subMenu || subMenu.length === 0) {
      return null;
    }

    return (
      <ul className="submenu-list">
        {subMenu.map((item) => (
          <li key={item.Menu} className="">
            <a
              id={`${item.name}_Active`}
              href={item.path || "#"}
              className="submenu-link"
            >
              {item.Menu} &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {item.subMenu && renderSubMenu(item.subMenu)}
          </li>
        ))}
      </ul>
    );
  };

  const handleClick = () => {
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    $(document).ready(function () {
      var scroll_start = 0;
      var startchange = $("#homePage");
      var offset = startchange.offset();
      if (startchange.length) {
        $(document).scroll(function () {
          scroll_start = $(this).scrollTop();
          var scrollHeight = $(document).height();
          var scrollPosition = $(window).height() + scroll_start;

          if (scroll_start > offset.top) {
            $("#nav-id").removeClass("nav-header-fixed");
            $("#nav-id").addClass("nav-header-add");
            $(".scroll-btn-down").css("display", "block");
            $(".logo").css("paddingTop", "10px");
          } else {
            $("#nav-id").addClass("nav-header-fixed");
            $("#nav-id").removeClass("nav-header-add");
            $(".scroll-btn-down").css("display", "none");
          }

          if (scrollPosition >= scrollHeight) {
            // Trigger an alert when the user reaches the bottom
            $(".scroll-btn").css("display", "none");
          } else {
            $(".scroll-btn").css("display", "block");
          }
        });
      }
    });
  });

  return (
    <>
      <AppBarWrapper className="nav-header-fixed" id="nav-id">
        <Toolbar>
          {/* Logo on the left */}
          <div
            sx={{ background: "transparent !important" }}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            {/* Insert your logo here */}
            <a href={`/`}>
              <img loading="lazy" className="logo" src={logo} alt="Logo" />
            </a>
          </div>

          {/* Right-aligned options */}
          <div style={{ marginLeft: "auto" }}>
            <div className="right-option ">
              {isMatch ? (
                <IconButton color="inherit" onClick={handleClick}>
                  <MenuIcon
                    sx={{
                      fontSize: "40px",
                    }}
                  />
                </IconButton>
              ) : (
                <>
                  <nav>
                    <section className="navbar-center">
                      <span className="overlay"></span>
                      <div
                        className={`menu ${isMenuActive ? "is-active" : ""}`}
                      >
                        <div className="menu-header">
                          <span className="menu-arrow" onClick={hideSubMenu}>
                            <i className="bx bx-chevron-left"></i>
                          </span>
                          <span className="menu-title"></span>
                        </div>
                        <ul className="menu-inner" onClick={toggleSubMenu}>
                          {MenuList.map((menuItem) => (
                            <li
                              id={`active_path_${menuItem.Menu}`}
                              key={menuItem.Menu}
                              className={`menu-item  ${
                                menuItem.subMenu ? "menu-dropdown" : ""
                              }`}
                            >
                              {menuItem.path === "" ? (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "start",
                                  }}
                                >
                                  {menuItem.Menu}
                                </span>
                              ) : (
                                <a
                                  href={menuItem.path || "#"}
                                  className="menu-link"
                                >
                                  {menuItem.Menu}
                                </a>
                              )}

                              {menuItem.subMenu && showSubMenu && (
                                <div
                                  className={`submenu megamenu ${
                                    menuItem.Menu === "Resources"
                                      ? "megamenu-column-5"
                                      : "megamenu-column-4"
                                  }`}
                                >
                                  {menuItem.subMenu.map((subMenuGroup) => (
                                    <div
                                      key={subMenuGroup.Menu}
                                      className="submenu-inner"
                                    >
                                      <h4 className="submenu-title">
                                        {subMenuGroup.Menu === "Case Studies" ||
                                        subMenuGroup.Menu === "Blogs" ||
                                        subMenuGroup.Menu === "Newsroom" ? (
                                          <a
                                            href={subMenuGroup.path || "#"}
                                            className="submenu-link"
                                            id={`${subMenuGroup.name}_Active`}
                                          >
                                            {subMenuGroup.Menu}{" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </a>
                                        ) : (
                                          <>{subMenuGroup.Menu}</>
                                        )}
                                      </h4>
                                      {renderSubMenu(subMenuGroup.subMenu)}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </section>
                  </nav>
                </>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBarWrapper>

      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        className="Drawer-main"
      >
        <div
          style={{
            margin: "0px 30px",
          }}
        >
          <div
            className="top-header"
            style={{
              margin: "30px 0px",
            }}
          >
            <div
              sx={{ background: "transparent !important" }}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              {/* Insert your logo here */}
              <a href={`/`}>
                <img
                  loading="lazy"
                  className="aays-logo"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>
            <IconButton color="inherit" onClick={() => setIsDrawerOpen(false)}>
              <CloseIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </IconButton>
          </div>

          <div>
            <div
              className="section4TypographyWrapper"
              style={{
                marginLeft: "10px",
              }}
            >
              <div
                id="solution"
                className="align"
                onClick={() => {
                  // setResources(false);
                  // setSolution(!solution);
                  // highlight("solution");
                  // removeHighlight("resource");
                  // removeHighlight("about");
                  // removeHighlight("career");
                  setResources(false);
                  setSubDrawerOpen(true);
                  setSolution(true);
                }}
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                  }}
                  className=" font-family "
                  variant="h4"
                >
                  <b> Solutions</b>
                </Typography>
                <ArrowIcon width="10%" />
              </div>

              <div
                id="applications"
                className="align"
                onClick={() => {
                  // setResources(false);
                  // setSolution(!solution);
                  // highlight("solution");
                  // removeHighlight("resource");
                  // removeHighlight("about");
                  // removeHighlight("career");
                  setResources(false);
                  setSubDrawerOpen(true);
                  setSolution(false);
                  setApplicatinos(true);
                }}
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                  }}
                  className=" font-family "
                  variant="h4"
                >
                  <b> Applications</b>
                </Typography>
                <ArrowIcon width="10%" />
              </div>
              {solution && (
                <Box
                  className="solution-subtext"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gridGap: "10px",
                    marginLeft: "20px",
                    // padding: "20px 0px",
                  }}
                >
                  <Box
                    onClick={() => {
                      setIndustry(!industry);
                      setCapabilities(false);
                      setFunctional(false);
                      highlight("industry");
                      removeHighlight("solution");
                    }}
                    className="mouse-pointer"
                  >
                    <Box
                      id="industry"
                      sx={{
                        display: "flex",
                        gridGap: "10px",
                      }}
                    >
                      <Typography variant="h6">
                        {" "}
                        <b>By Industries</b>{" "}
                      </Typography>
                      {/* <ArrowOutwardIcon /> */}
                    </Box>
                    {industry && (
                      <Box
                        className="functions-subtext"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gridGap: "10px",
                          marginLeft: "20px",
                          padding: "20px 0px",
                        }}
                      >
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-solutions-cpg"
                            >
                              Consumer Packaged Goods
                            </a>
                          </Typography>
                        </div>
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-solutions-cpg"
                            >
                              Food & Beverage
                            </a>
                          </Typography>
                        </div>
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <Link>Retail</Link>
                          </Typography>
                        </div>

                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-and-analytics-in-manufacturing"
                            >
                              {" "}
                              Manufacturing{" "}
                            </a>
                          </Typography>
                        </div>

                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-and-analytics-in-automotive-industry"
                            >
                              Automotive
                            </a>
                          </Typography>
                        </div>
                      </Box>
                    )}
                  </Box>

                  <Box
                    onClick={() => {
                      setIndustry(false);
                      setFunctional(!functional);
                      setCapabilities(false);
                      highlight("functional");
                      removeHighlight("industry");
                      removeHighlight("solution");
                    }}
                    className="mouse-pointer"
                  >
                    <Box
                      id="functional"
                      sx={{
                        display: "flex",
                        gridGap: "10px",
                      }}
                    >
                      <Typography variant="h6">
                        {" "}
                        <b>By Functions</b>{" "}
                      </Typography>
                      {/* <ArrowOutwardIcon /> */}
                    </Box>

                    {functional && (
                      <Box
                        className="functions-subtext"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gridGap: "10px",
                          marginLeft: "20px",
                          padding: "20px 0px",
                        }}
                      >
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/supply-chain-analytics"
                            >
                              Supply Chain & Procurement
                            </a>
                          </Typography>
                        </div>
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-and-finance-analytics"
                            >
                              Commercial & Finance
                            </a>
                          </Typography>
                        </div>
                      </Box>
                    )}
                  </Box>

                  <Box
                    onClick={() => {
                      setIndustry(false);
                      setFunctional(false);
                      setCapabilities(!capabilities);
                      highlight("capabilities");
                      removeHighlight("solution");
                      removeHighlight("industry");
                      removeHighlight("functional");
                    }}
                    className="mouse-pointer"
                  >
                    <Box
                      id="capabilities"
                      sx={{
                        display: "flex",
                        gridGap: "10px",
                      }}
                    >
                      <Typography variant="h6">
                        {" "}
                        <b>By Capabilities</b>{" "}
                      </Typography>
                      {/* <ArrowOutwardIcon /> */}
                    </Box>
                    {capabilities && (
                      <Box
                        // onMouseEnter={() => setIndustry(true)}
                        // onMouseLeave={() => setIndustry(false)}
                        className="functions-subtext"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gridGap: "10px",
                          marginLeft: "20px",
                          padding: "20px 0px",
                        }}
                      >
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-and-data-engineering"
                            >
                              Data Engineering
                            </a>
                          </Typography>
                        </div>
                        <div className="align" id="about">
                          <Typography
                            sx={{
                              fontSize: "18px",
                            }}
                            className=" font-family "
                            variant="h4"
                          >
                            <a
                              className="mobileFontSize"
                              href="/ai-innovations-enterprise-solutions"
                            >
                              AI Innovations
                            </a>
                          </Typography>
                        </div>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              <div
                id="resource"
                className="align"
                onClick={() => {
                  // setResources(!resources);
                  // setSolution(false);
                  // highlight("resource");
                  // removeHighlight("solution");
                  // removeHighlight("about");
                  // removeHighlight("career");
                  setSolution(false);
                  setSubDrawerOpen(true);
                  setResources(true);
                }}
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                  }}
                  className=" font-family "
                  variant="h4"
                >
                  <b> Resources</b>
                </Typography>
                <ArrowIcon width="10%" />
              </div>
              {resources && (
                <Box
                  className="functions-subtext"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gridGap: "10px",
                    marginLeft: "20px",
                  }}
                >
                  <div className="align" id="about">
                    <Typography
                      sx={{
                        fontSize: "18px",
                      }}
                      className=" font-family mobileFontSize"
                      variant="h4"
                    >
                      <a href="/customer-success-stories">
                        <b>Case Studies </b>
                      </a>
                    </Typography>
                  </div>
                  <div className="align" id="about">
                    <Typography
                      sx={{
                        fontSize: "18px",
                      }}
                      className=" font-family mobileFontSize"
                      variant="h4"
                    >
                      <a href="/blog">
                        <b>Blogs </b>
                      </a>
                    </Typography>
                  </div>
                  <div className="align" id="about">
                    <Typography
                      sx={{
                        fontSize: "18px",
                      }}
                      className=" font-family mobileFontSize"
                      variant="h4"
                    >
                      <a href="/newsroom">
                        <b>Newsroom </b>
                      </a>
                    </Typography>
                  </div>
                </Box>
              )}

              <a
                href="/about"
                className="align"
                id="about"
                onClick={() => {
                  setResources(false);
                  setSolution(false);
                  highlight("about");
                  removeHighlight("solution");
                  removeHighlight("career");
                  removeHighlight("resource");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                  }}
                  className=" font-family "
                  variant="h4"
                >
                  <div>
                    <b> About</b>
                  </div>
                </Typography>
                <ArrowIcon width="10%" />
              </a>

              <a
                href="career"
                className="align"
                id="career"
                onClick={() => {
                  setResources(false);
                  setSolution(false);
                  highlight("career");
                  removeHighlight("about");
                  removeHighlight("resource");
                  removeHighlight("solution");
                }}
              >
                <Typography
                  sx={{
                    fontSize: "28px",
                  }}
                  className=" font-family "
                  variant="h4"
                >
                  <div>
                    <b>Career</b>
                  </div>
                </Typography>
                <ArrowIcon width="10%" />
              </a>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        open={subDrawerOpen}
        onClose={() => handleSubDrawerClose()}
        className="sub-drawer"
      >
        <div style={{ padding: "0 30px" }}>
          <div
            className="top-header"
            style={{
              margin: "30px 0px",
            }}
          >
            <div
              sx={{ background: "transparent !important" }}
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ display: "flex", alignItems: "center" }}
              className="back-arrow"
              onClick={() => {
                handleSubDrawerClose();
                setIsDrawerOpen(true);
              }}
            >
              <ArrowIcon width="10%" style={{ transform: "rotate(180deg)" }} />
              Menu
            </div>

            <IconButton color="inherit" onClick={() => handleSubDrawerClose()}>
              <CloseIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </IconButton>
          </div>

          {solution && (
            <div className="menu-container">
              <div className="menu-heading">Solutions</div>
              {mobileSolutionList.map((ele) => (
                <div key={ele.name}>
                  <div
                    className="menu-content"
                    onClick={() => {
                      setSolutionMobile((prevState) =>
                        prevState === ele.name ? "" : ele.name
                      );
                    }}
                  >
                    {ele.Menu}{" "}
                    <ArrowIcon
                      width="10%"
                      style={{
                        transform:
                          solutionMobile === ele.name ? "rotate(90deg)" : "",
                      }}
                    />
                  </div>

                  {solutionMobile === ele.name &&
                    ele.subMenu.map((obj, index) => (
                      <a
                        key={index}
                        href={obj.path}
                        className="menu-sub-content"
                      >
                        {obj.Menu}
                      </a>
                    ))}
                </div>
              ))}
            </div>
          )}

          {resources && (
            <div className="menu-container">
              <div className="menu-heading">Resources</div>
              {mobileResorceList.map((ele) => (
                <>
                  <a className="menu-content" href={ele.path}>
                    {ele.Menu} <ArrowIcon width="10%" />
                  </a>
                </>
              ))}
            </div>
          )}
          {applications && (
            <div className="menu-container">
              <div className="menu-heading">Applications</div>
              {mobileApplicationsList.map((ele) => (
                <>
                  <a className="menu-content" href={ele.path}>
                    {ele.Menu} <ArrowIcon width="10%" />
                  </a>
                </>
              ))}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
