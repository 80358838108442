import React from "react";
import Industry from "../../assets/img/Industry1.png";
import capabilities from "../../assets/img/capabilities1.png";
import functions from "../../assets/img/functions1.png";

function HomePageSection4(props) {
  const { heading, subheading } = props;
  return (
    <div className="homepage-section4">
      <div className="main homepage-sections-padding ">
        <div className="main-heading">
          <h2>{heading}</h2>
        </div>

        <div className="grid-container">
          <span>{subheading}</span>
        </div>

        {/* Box1 */}

        <div className="services">
          <div className="box">
            <div className="icon">
              <div>
                <img loading="lazy" src={Industry} alt="" />
                <h2>Industries</h2>
              </div>
            </div>
            <div className="content">
              <div>
                <a href="/ai-solutions-cpg">
                  <span className="first-span">CPG</span>
                </a>
                <a href="/ai-solutions-for-food-and-beverage-companies">
                  <span>Food & Beverage</span>
                </a>
                <a href="/ai-and-analytics-in-automotive-industry">
                  <span>Automotive</span>
                </a>
                <a href="/ai-and-analytics-in-manufacturing">
                  <span>Manufacturing</span>
                </a>
              </div>
            </div>
          </div>

          {/* //box2 */}

          <div className="box">
            <div className="icon">
              <div>
                <img loading="lazy" src={functions} alt="" />
                <h2>Functions</h2>
              </div>
            </div>
            <div className="content">
              <div>
                <a href="/supply-chain-analytics">
                  <span className="first-span">
                    Supply Chain & <br /> Procurement
                  </span>
                </a>
                <a href="/ai-and-finance-analytics">
                  <span>
                    Commercial and <br />
                    Finance
                  </span>
                </a>
                <p>
                  <span>Operations</span>
                </p>
              </div>
            </div>
          </div>

          {/* //box3 */}

          <div className="box">
            <div className="icon">
              <div>
                <img loading="lazy" src={capabilities} alt="" />
                <h2>Capabilities</h2>
              </div>
            </div>
            <div className="content">
              <div>
                <a
                  style={{ fontSize: "20px" }}
                  href="/data-ai-and-data-engineering"
                >
                  <span> Data Engineering</span>
                </a>
                <a href="/ai-innovations-enterprise-solutions">
                  <span>AI Innovation</span>
                </a>
                <p>
                  <span>Visualization</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageSection4;
