import { Helmet } from "react-helmet";
import { useState } from "react";
import $ from "jquery";
import Navbar from "../../../nav/NavBar";
import "./newBlog.scss";
import "./newBlogResponsive.scss";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import KeyPoints from "./Keypoints";
import { KeyPointGenAIData } from "./KeyPointData";
import aaysInGenai from "../../../assets/img/genai/aays-in-genai.png";
import badge from "../../../assets/img/genai/badge-01.png";
import logo from "../../../assets/img/logo.png";
import Footer from "../../../nav/Footer";
import { useMediaQuery, useTheme } from "@mui/material/";
import { useEffect } from "react";

const BestFirm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isloading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  useEffect(() => {
    document.title = "Aays | Ranked Seasoned Vendor for Gen AI";
    document.getElementsByTagName("META")[3].content =
      "Aays recognized as a Seasoned Vendor in AIM Research's Gen AI PeMa Quadrant. Discover our leading Gen AI services and expertise highlighted in the Gen AI Report.";
  });

  const rejectList = ["effem.com"];

  const downloadFinanceController = async () => {
    console.log("download function called");
    try {
      const response = await fetch(
        "https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/Top-Generative-AI-Service-Providers-2023.pdf"
      );

      console.log(response, "res");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      console.log(url, "url");
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute(
        "download",
        `Top-Generative-AI-Service-Providers-2023-${new Date().getTime()}.pdf`
      );

      document.body.appendChild(link);
      console.log(link, "link");
      link.click();
    } catch (error) {
      console.error("Error downloading finance controller:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    var URL =
      "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";

    var name = $("#fullName").val();
    var phone = "No phone number";
    var email = $("#email").val();
    var desg = $("#designation").val();
    var message = "";
    var company = $("#companyName").val();
    var splitArray = email.split("@");
    if (rejectList.indexOf(splitArray[1]) >= 0) {
      message = "View market modelling case study restricted";
    } else {
      // Remove ".html" extension

      message = "View Market Modelling case study";
    }

    var contactUsData = {
      name: name,
      phone: phone,
      email: email,
      designation: desg,
      message: message,
      company: company,
    };

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(contactUsData),

      success: function () {
        console.log("successfully submittedddd");
        // clear form and show a success message
        setisLoading(false);

        if (rejectList.indexOf(splitArray[1]) >= 0) {
          console.log("rejected");
          setisError(true);
          setTimeout(() => {
            setisError(false);
          }, 5000);
          return false;
        } else {
          console.log("view");
          downloadFinanceController();
        }
      },
      error: function () {
        setisError(true);
        setisLoading(false);

        setTimeout(() => {
          setisError(false);
        }, 5000);
      },
    });
  };

    const data = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "All Blogs",
        url: "/Blog",
      },
    ];

  return (
    <>
      <div id="homePage">
        {/* // helmet tag */}
        <Helmet>
          <meta
            name="keywords"
            content="Aays Gen AI, Seasoned Vendor AIM Research, Gen AI PeMa Quadrant, Gen AI Report, Gen AI Services"
          />
        </Helmet>
        <Navbar />
        <div className="newBlog-body">
          <div className="newBlog-margin">
            <div className="newBlog-main">
              <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "white" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="firstSection">
                <div className="main">
                  <h1 className="heading">
                    Aays Recognized as One of the 50 Best Firms for Data
                    Scientists to Work for in 2024
                  </h1>
                  <div className="date-read-container">
                    <div className="date-container">
                      <div className="date"> June 28, 2024 </div>
                      <div className="read">
                        <AccessTimeIcon
                          style={{
                            fontSize: "14px",
                          }}
                        />{" "}
                        <span> 2 min read</span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="box-form best-firm-background"></div>
                  <div className="content1">
                    <div className="text1">
                      AIM Research conducted an extensive survey of employers
                      across the country to reveal how they foster excellent
                      work environments for data scientists.
                    </div>
                    <div>
                      We are thrilled to share that Aays has been featured in
                      the list of the "50 Best Firms for Data Scientists to Work
                      For in 2024.
                    </div>
                  </div>
                </div>
              </div>

              <div className="quotes">
                <div className="main">
                  <div className="content">
                    <p className="content1">
                      We are not only included in the list, but we have been
                      featured in the "Workplace Leaders" category and are
                      especially proud to be ranked 13th among the 100+
                      organizations surveyed in the data science space.
                    </p>
                    <p className="content2">
                      The report provides an in-depth analysis of the
                      employee-centric policies implemented by top companies,
                      focusing on creating exemplary work environments for data
                      scientists. The top indices based on which the employers
                      were evaluated were: Learning & Support, Productivity &
                      Engagement, Benefits & Well-being, Rewards & Excellence,
                      and Diversity & Inclusion. Read the complete{" "}
                      <a href="https://aimresearch.co/penetration-maturity-pema-matrix/top-data-engineering-service-providers-pema-quadrant-2024">
                        <span className="link">Report here</span>
                      </a>
                      .
                    </p>
                  </div>
                  <div className="container">
                    <div className="quote-box">
                      <div className="heading">
                        Dwarika Patro, COO & Co-Founder, Aays
                      </div>
                      <div className="content">
                        <span className="size">“</span> Our incredible team
                        members are the core of everything we do. We are
                        committed to consistently offering them growth
                        opportunities and competitive benefits to support their
                        overall career growth and well-being.{" "}
                        <span className="size">”</span>
                      </div>
                    </div>
                    <div className="quote-box">
                      <div className="heading">
                        Anshuman Bhar, CEO & Co-Founder, Aays
                      </div>
                      <div className="content">
                        <span className="size">“</span> This recognition
                        highlights our focus on employee engagement, well-being,
                        and providing the right opportunities for learning and
                        development. A big thank you to the entire team for
                        making this possible.
                        <span className="size">”</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BestFirm;
