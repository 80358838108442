import React, { useState } from "react";
import CustomerSectionBox1 from "../../reuseLayout/CustomerSectionBox1";
import ai1 from "../../assets/img/ai1.jpg";
import ai2 from "../../assets/img/ai2.png";
import ai3 from "../../assets/img/ai3.png";
import ai4 from "../../assets/img/ai4.jpg"

const Section3 = () => {
  const [limit, setLimit] = useState(2);

  const items = [
    {
      title:
        "Data analytics is transforming the way CPG companies make pricing decisions",
      picture: ai1,
      date: "Aug 8, 2023 ",
      read: "2 min read",
      redirectURL : '/blogs/transforming-pricing-decisions-in-CPG-companies'
    },
    {
      title: "Data analytics to optimize CPG supply chain costs",
      picture: ai2,
      date: "May 4, 2023 ",
      read: "2 min read",
      redirectURL : '/blogs/data-analytics-in-consumer-packaged-goods'

    },
    {
      title: "How can analytics increase the reliability of the supply chain?",
      picture: ai3,
      date: "April 13, 2023  ",
      read: "2 min read",
      redirectURL : '/blogs/supply-chain-analytics'


    },
    {
      title: "How is big data and analytics helping the automotive industry?",
      picture: ai4,
      date: "Feb 16, 2023",
      read: "2 min read",
      redirectURL : '/blogs/How-is-big-data-and-analytics-helping-the-automotive-industry'

    }
  ];

  const visibleItems = items.slice(0, limit);

  const handleSeeMoreClick = () => {
    setLimit((prevLimit) => prevLimit + 2);
  };

  const handleSeeLessClick = () => {
    setLimit(2);
  };

  return (
    <div>
      <div
        className="success-story-page-section-3 scroll-section"
        id="success-story-page-section-3"
      >
        <div className="success-story-page-section-3-margin">
          <div className="success-story-page-section-3-Header-text">
            Supply Chain & CPG
          </div>
          <div className="success-story-page-section-3-grid">
            {visibleItems.map((item, index) => (
              <CustomerSectionBox1
                key={index}
                title={item.title}
                picture={item.picture}
                date={item.date}
                read={item.read}
                redirectURL={item.redirectURL}
                class="success-story-page-section-3-grid-items-texta"
              />
            ))}
          </div>

          {limit < items.length ? (
            <div className="seeMore" onClick={handleSeeMoreClick}>
              See More
            </div>
          ) : (
            <></>
            // <div className="seeMore" onClick={handleSeeLessClick}>
            //   See Less
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section3;
