import React from "react";
import Navbar from "../nav/NavBar";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import CareerPage from "../pageLayout/CareerPageLayout/CareerPage";
import Footer from "../nav/Footer";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import { useMediaQuery } from "@mui/material/";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/career-banner-right.png";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";
const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + (90 * $(window).height()) / 100);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - (90 * $(window).height()) / 100);
};

function Career() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div className="career-main" id="homePage">
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Empower Your Career  "
          text2="Journey at Aays"
          subtext1="Where Your Growth Comes First "
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Empower Your Career  "
          text2="Journey at Aays"
          subtext1="Where Your Growth Comes First "
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Empower Your Career  "
          text2="Journey at Aays"
          subtext1="Where Your Growth Comes First "
          picture={mobileBannerImage}
        />
      )}

      <MiddleBanner text="Come Join Us at Aays | A Team that Inspires Excellence" />
      <CareerPage />
      <BottomBanner contentChange={true} />
      <Footer />
      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default Career;
