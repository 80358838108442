import React from "react";

function HomePageSection2() {
  return (
    <div>
      <div className="section2-homepage-main">
        <div className="homepage-body homepage-sections-padding">
          <div className="mainContainer">
            <div className="topContainer">
              <div className="box">
                <div>
                  <h2 className="counting-number">
                    {/* {counterOn && (
                  <CountUp start={0} end={100} duration={2} delay={0} />
                )} */}
                    $200M
                  </h2>
                  <span className="text">
                  Supply Chain  <br /> Optimization
                  </span>
                </div>
              </div>

              <div className="box box-border">
                <div>
                  <h2 className="counting-number">$500M</h2>
                  <span className="text">
                  Working Capital <br /> Optimization
                  </span>
                </div>
              </div>

              <div className="box">
                <div>
                  <h2 className="counting-number">$400M</h2>
                  <span className="text">
                    {" "}
                    Revenue Leakage <br /> Visibility
                  </span>
                </div>
              </div>
            </div>

            {/* // bottom container  */}
            <div className="bottomContainer">
              <div className="box">
                <div>
                  <h2 className="counting-number">
                    {/* {counterOn && (
                  <CountUp start={0} end={100} duration={2} delay={0} />
                )} */}
                    $300M
                  </h2>
                  <span className="text">
                  Profitability <br /> Improvement

                  </span>
                </div>
              </div>

              <div className="box box-border">
                <div>
                  <h2 className="counting-number">$600M</h2>
                  <span className="text">
                  ESG  <br/> Initiatives &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                  </span>
                </div>
              </div>

              <div className="box">
                <div>
                  <h2 className="counting-number">$450M</h2>
                  <span className="text">
                    {" "}
                    Network  &nbsp; &nbsp; &nbsp;&nbsp; <br/> Optimization 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageSection2;
