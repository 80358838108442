import React, { useState, useEffect } from "react";

const StickyButtons = () => {
  const [activeButton, setActiveButton] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".scroll-section");
      sections.forEach((section) => {
        const sectionId = section.id;
        const button = document.querySelector(`[href="#${sectionId}"]`);
        const sectionTop = section.offsetTop - window.innerHeight * 0.8; // Adjusted to include 80vh
        const sectionHeight = section.clientHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveButton(sectionId);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").slice(1);
    const targetSection = document.getElementById(targetId);
    targetSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="sticky-buttons">
      <div className="button-box">
        <div className="container">
          <a
            href="#section5-blog-success-story"
            className={
              activeButton === "section5-blog-success-story" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "section5-blog-success-story"
                  ? "first-btn active"
                  : "first-btn"
              }
            >
              Company Updates
            </button>
          </a>
          <a
            href="#aiAndGenerativeAi"
            className={activeButton === "aiAndGenerativeAi" ? "active" : ""}
            onClick={handleClick}
          >
            <button
              className={activeButton === "aiAndGenerativeAi" ? "active" : ""}
            >
              AI & Generative AI
            </button>
          </a>
          <a
            href="#section2-blog-success-story"
            className={
              activeButton === "section2-blog-success-story" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "section2-blog-success-story" ? "active" : ""
              }
            >
              Finance
            </button>
          </a>
          <a
            href="#success-story-page-section-3"
            className={
              activeButton === "success-story-page-section-3" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "success-story-page-section-3" ? "active" : ""
              }
            >
              Supply Chain & CPG
            </button>
          </a>
          <a
            href="#section4-blog-success-story"
            className={
              activeButton === "section4-blog-success-story" ? "active" : ""
            }
            onClick={handleClick}
          >
            <button
              className={
                activeButton === "section4-blog-success-story" ? "active" : ""
              }
            >
              Technology
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default StickyButtons;
