import React from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import tools1 from "../../assets/img/tools1.jpg";
import tools2 from "../../assets/img/tools2.jpg";
import tools3 from "../../assets/img/tools3.jpg";
import tools4 from "../../assets/img/tools4.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Section4() {
  return (
    <div
      id="section4-blog-success-story"
      className="blog-success-section2 scroll-section"
    >
      <div className="customer-section2-body margin-box">
        <div className="success-story-page-section-2-Header-text">
          Technology
        </div>
        <div className="customer-section2-body">
          <div className="section2-box">
            {/* <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            className="new-cls-blogs"
          > */}

            <Carousel
              // className="customer-success-main"

              responsive={responsive}
              dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              // showDots={isMobile? false : true}
            >
              <CustomerSectionBox
                picture={tools1}
                title1="Architectural Patterns for Analytics Journey Leveraging Azure Cloud Tech-Stack"
                date="Feb 14, 2022 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="/blogs/business-analytics-architecture"
              />
              <CustomerSectionBox
                picture={tools2}
                title1="New to Databricks? Here Are Some Useful Tips"
                date="Dec 06, 2021"
                class="content-box-section2a"
                read=" 2 min read"
                redirectURL="/blogs/databricks-tips-for-beginners"
              />
              <CustomerSectionBox
                picture={tools3}
                title1="Performance Optimization for Apache Spark"
                date="Nov 30, 2021"
                class="content-box-section2a"
                read="2 min read"
                redirectURL="/blogs/apache-spark-performance-optimization"
              />

              <CustomerSectionBox
                picture={tools4}
                title1="Common mistakes made by a Powerbi beginner & their best practice solutions"
                date="Oct 05, 2021"
                class="content-box-section2a"
                read="2 min read"
                redirectURL="/blogs/common-mistakes-made-by-a-Powerbi-beginner"
              />
            </Carousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
