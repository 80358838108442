import { useState } from "react";
import ContactUs from "../pageLayout/HomePageLayout/ContactUs";
import { Helmet, HelmetProvider } from "react-helmet-async";

const BottomBanner = ({ contentChange, removeNetwork }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <HelmetProvider>
      {/* // styling of this page in style.scss file */}

      <Helmet>
        <meta
          name="keywords"
          content="Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics"
        />
        <meta
          name="description"
          content="AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools."
        />
        <meta
          property="og:title"
          content="Finance Analytics and AI Solutions | Aays"
        />
        <meta
          property="og:image"
          content="https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png"
        />
      </Helmet>
      <div
        className="bottom-banner"
        style={removeNetwork ? { position: "relative" } : {}}
      >
        <div className="body">
          {!contentChange ? (
            <div className="main">
              <div className="heading">Take the Next Step</div>
              <div className="content">
                See how AaDi can streamline your finance processes and enhance
                your management reporting with features like, advanced
                root-cause analysis, flux reporting, anomaly detection, and
                more.
              </div>
              <div className="button-wrapper">
                <button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Request a Demo
                </button>
              </div>
            </div>
          ) : (
            <div className="main">
              <div className="heading">
                Take the Next Step to AI-Driven Success
              </div>
              <div className="content">
                Break down data silos. Accelerate insights. Optimize operations.
                Our enterprise-grade AI solutions transform corporate finance
                analytics and streamline supply chains with advanced AI
                technologies.
              </div>
              <div className="button-wrapper">
                <button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Contact Us
                </button>
              </div>
            </div>
          )}
          <ContactUs openModal={openModal} setOpenModal={setOpenModal} />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default BottomBanner;
