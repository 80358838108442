import React from 'react';
import { AccoladesData } from '../HomePageLayout/AccoladesData';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min:1300 },
        items: 4,
        slidesToSlide: 4,
    },
    smallDesktop: {
        breakpoint: { max: 1300, min: 1025 },
        items: 3,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 1025, min: 600 },
        items: 2,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

const Sectionnew = () => {
    const accoladesWrapperStyle = {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '7px',
    };

    const imageContainerStyle = {
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
       // width: '100%', 
       width: '250px',
       
        height: '210px', //200
        border: '1px solid #181818',
       
        overflow: 'hidden',
        backgroundColor: '#181818', 
    }

    const imageStyle = {
       maxWidth: '100%',
        maxHeight: '100%', 
        objectFit: 'cover', 
        padding: '10px',
    };

    const textStyle = {
        marginTop: '10px',
        fontSize: '13px',
        textAlign:'center',
    };

    const mainStyle = {
        marginTop: '28px',
        backgroundColor: 'transparent',
        padding: '0',
        width: '100%', 
        position: 'relative', 
        left: '50%',
        transform: 'translateX(-50%)',
    };

    const headingStyle = {
        fontWeight: 700,
        textAlign: 'center',
        margin: '0 auto',
        width: '100%', 
        color: '#fff',
        fontSize: '30px',
        paddingBottom: '45px'
       
        
    };
    const boxHeadingStyle = {
        fontSize: '18px'
    }


    return (
        <div id="accolades" style={mainStyle}>
            <div className="main" style={{ width: "100%"}} >
                <div className="heading-main" style={headingStyle}>Accolades and Recognitions</div>
                <Carousel
                    responsive={responsive}
                    dotListClass="custom-dot-list-style"
                    arrows={false}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    infinite={true}
                    slidesToSlide={1}
                    swipeable={true}
                    draggable={false}
                    containerClass="carousel-container"
                >
                    {AccoladesData.map((data) => (
                        <div key={data.id} style={accoladesWrapperStyle}>
                            <div style={imageContainerStyle}>
                                <img src={data.image} alt={data.heading} style={imageStyle} />
                            </div>
                            <div style={textStyle}>
                                <div style={boxHeadingStyle} >{data.heading}</div>
                                <p>{data.description}</p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default Sectionnew;
      

