import React, { useEffect } from "react";
import Navbar from "../nav/NavBar";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import Footer from "../nav/Footer";
import FinanceImg1 from "../assets/img/cpg-page-icon-5.png";
import FinanceImg2 from "../assets/img/cpg-page-icon-4.png";
import FinanceImg3 from "../assets/img/cpg-page-icon-2.png";
import FinanceImg4 from "../assets/img/cpg-page-icon-3.png";
import FinanceImg5 from "../assets/img/cpg-page-icon-1.png";
import CommercialFinnance from "../assets/img/cpg-icon-5.png";
import Shipment from "../assets/img/cpg-icon-8.png";
import SupplyChain from "../assets/img/cpg-icon-1.png";
import Inventory from "../assets/img/cpg-icon-6.png";
import Risk from "../assets/img/cpg-icon-7.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import supplyImg3 from "../assets/img/Vendor Risk Management.png";
import dailySalesPositioning from "../assets/img/Daily Sales Positioning .png";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../pageLayout/AboutPageLayout/Section5";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import infographic from "../assets/img/cpg-infographic.png";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import animationImage from "../assets/img/cpg-banner-right.png";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";

const Data = [
  {
    id: 1,
    image: supplyImg3,
    heading: "Data Engineering",
  },
  {
    id: 2,
    image: dailySalesPositioning,
    heading: "  AI-Led Innovations",
  },
  {
    id: 3,
    image: supplyImg3,
    heading: "Visualization & Reporting",
  },
];

const DataLed = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  useEffect(() => {
    document.title = "AI/ML Solutions by Aays | CPG";
    document.getElementsByTagName("META")[3].content =
      "Advanced AI and analytics solutions for CPG industries. Enhance operations and decision-making with Aays's AI/ML capabilities for CPG businesses  ";
  });

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + (90 * $(window).height()) / 100);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - (90 * $(window).height()) / 100);
  };

  return (
    <div id="homePage" className="data-led-main">
      <Helmet>
        <meta
          name="keywords"
          content="AI tools for CPG, CPG AI Solutions, AI Solutions for CPG, analytics tools for CPG, CPG analytics Solutions, analytics Solutions for CPG, AI/ML in CPG"
        />
      </Helmet>
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Redefining CPG Dynamics"
          text2="with AI Solutions"
          subtext1="Comprehensive Solutions for"
          subtext2="the Entire Consumer Goods Value Chain"
          picture={animationImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Redefining CPG Dynamics"
          text2="with AI Solutions"
          subtext1="Comprehensive Solutions for"
          subtext2="the Entire Consumer Goods Value Chain"
          picture={animationImage}
        />
      ) : (
        <NewHeaderSection
          text1="Redefining CPG Dynamics"
          text2="with AI Solutions"
          subtext1="Comprehensive Solutions for"
          subtext2="the Entire Consumer Goods Value Chain"
          picture={animationImage}
        />
      )}

      <MiddleBanner text="Collaborating with 3 Global Fortune companies in the CPG industry, we are pioneering AI-driven transformations to deliver unprecedented value" />

      <div className="data-led-section3">
        <div className="margin-80">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "35% 65%",
              gridGap: "1rem",
              alignItems: "center",
              alignContent: "center",
            }}
            className="ConsumerGoods"
          >
            <div>
              <div
                style={{ display: "grid", textAlign: "left", gridGap: "0px" }}
                className="header-text-new"
              >
                <span
                  className="data-led-ConsumerGoods-text1"
                  style={{ color: "#bb1ccc" }}
                >
                  {" "}
                  Empowering AI-driven Solutions{" "}
                  <b style={{ color: "white" }}>throughout</b>{" "}
                  <span
                    style={{
                      color: "white",
                    }}
                    className="data-led-ConsumerGoods-text2"
                  >
                    the Consumer Goods Value Chain
                  </span>
                </span>
              </div>
            </div>

            <div className="GridLayoutNew">
              <div class="flip-card" tabIndex="0">
                <div class="flip-card-inner">
                  <div class="flip-card-front flip-card-food">
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <span>
                        <img
                          style={{ width: "70px" }}
                          src={FinanceImg1}
                          alt="Food"
                        />
                      </span>
                      <span> Food & Beverage</span>
                    </div>
                  </div>
                  <div class="flip-card-back">
                    <h3 class="landing_page_subheader">
                      Optimize supply chain logistics and quality control with
                      AI-powered solutions tailored for the Food & Beverage
                      sector, utilizing predictive analytics for enhanced
                      efficiency.
                    </h3>
                  </div>
                </div>
              </div>

              <div className="middle-item">
                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            style={{ width: "60px" }}
                            src={FinanceImg2}
                            alt="Food"
                          />
                        </span>
                        <span>Retail</span>
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <h3 class="landing_page_subheader">
                        Leverage AI-driven customer analytics to personalize
                        experiences, optimize inventory management, and drive
                        growth within the retail landscape.
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            style={{ width: "54px" }}
                            src={FinanceImg3}
                            alt="Food"
                          />
                        </span>
                        <span>Fashion</span>
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <h3 class="landing_page_subheader">
                        Harness data-driven insights and AI algorithms to
                        anticipate trends, personalize offerings, and streamline
                        supply chains in the fashion industry.
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            style={{ width: "70px" }}
                            src={FinanceImg5}
                            alt="Food"
                          />
                        </span>
                        <span>FMCG</span>
                      </div>{" "}
                    </div>
                    <div class="flip-card-back">
                      <h3 class="landing_page_subheader">
                        Swiftly adapt to market changes through predictive
                        analytics-driven FMCG solutions, optimizing operations
                        and forecasting demands with AI-powered insights.
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      {" "}
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span>
                          <img
                            style={{ width: "74px" }}
                            src={FinanceImg4}
                            alt="Food"
                          />
                        </span>
                        <span>Manufacturing</span>
                      </div>{" "}
                    </div>
                    <div class="flip-card-back">
                      <h3 class="landing_page_subheader">
                        Implement AI and predictive maintenance analytics to
                        optimize production processes, reduce downtime, and
                        optimize resource allocation within manufacturing.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="data-led-section4">
        <div>
          <img
            style={{ width: "100%", paddingTop: "2rem" }}
            src={infographic}
            alt="infograpic"
          />
        </div>
      </div>

      <div className="data-led-section5">
        <h1 className="header-text-new">
          Additional Key Areas that We Focus on
        </h1>
        <div className="margin-80">
          <div className="data-led-section-grid">
            <div className="item-img">
              <span className="image-div">
                <img
                  loading="lazy"
                  className="feature-icon"
                  src={Shipment}
                  alt="Finance"
                />
              </span>
              <span className="text-icon">Shipment Volume Management</span>
            </div>
            <div className="item-img">
              <span className="image-div">
                <img
                  loading="lazy"
                  className="feature-icon"
                  src={Risk}
                  alt="Finance"
                />
              </span>{" "}
              <span className="text-icon">Risk-Prone Fulfillment</span>
            </div>
            <div className="item-img">
              <span className="image-div">
                <img
                  loading="lazy"
                  className="feature-icon"
                  src={Inventory}
                  alt="Finance"
                />
              </span>{" "}
              <span className="text-icon">Inventory Coverage & Freshness</span>
            </div>
            <div className="item-img">
              <span className="image-div">
                <img
                  loading="lazy"
                  className="feature-icon"
                  src={SupplyChain}
                  alt="Finance"
                />
              </span>{" "}
              <span className="text-icon">Supply Chain Optimization</span>
            </div>
            <div className="item-img">
              <span className="image-div">
                <img
                  className="feature-icon"
                  src={CommercialFinnance}
                  alt="Finance"
                />
              </span>{" "}
              <span className="text-icon">Commercial & Finance</span>
            </div>
          </div>
        </div>

        <div className="flex-box-additional-key-main">
          <div className="flex-box-additional-key">
            <div className="DataEngagemnts">
              200+{" "}
              <span style={{ fontSize: "18px", fontWeight: "500" }}>
                Data Engagements
              </span>{" "}
            </div>
            <span className="angleLeft">
              <ChevronRightIcon />
            </span>
            <div>
              {" "}
              <span style={{ fontSize: "18px", fontWeight: "500" }}>
                Over
              </span>{" "}
              2Bn+{" "}
              <span style={{ fontSize: "18px", fontWeight: "500" }}>
                Business impact
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="data-led-section6">
        <div>
          <div className="header-text-new-1">
            Holistic Value Chain Expertise in Data and AI
          </div>
          <div
            style={{ width: "60%", margin: "auto", display: "block" }}
            className="sub-header-text-new"
          >
            At Aays, our expertise spans the complete spectrum of Data and AI,
            commencing from architecture setup to actionable insights
          </div>

          <div className="margin-80">
            <div className="success-story-page-section-4-grid">
              {Data.map((item) => (
                <div className="success-story-page-section-4-grid-items">
                  <img loading="lazy" src={item.image} alt={item.heading} />
                  <div className="success-story-page-section-4-grid-items-text">
                    {item.heading}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection data={CustomerSuccessStoriesData} tag="cpg" />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="cpg"
            show="true"
          />
        )}
      </div>
      <BottomBanner contentChange={true} />
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default DataLed;
