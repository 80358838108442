import React from "react";
import roboImage from "../../assets/img/careerPage-mainImage.png";

function Section1() {
  return (
    <div className="career-section1-new ">
      <div className="section-box">
        <div className="grid-item1">
          <span className="heading">
            Join a Team <br /> that Inspires Excellence
          </span>
          <p className="content">
            Welcome to Aays, India's fastest growing data science powerhouse! At
            Aays, we thrive on tackling the most complex business challenges
            with a fusion of consulting, big data, and AI/ML wizardry. Join us
            in our quest for innovation, where our employee-first culture spans
            across India, Singapore, and UK. We're not just another data science
            company; we're a certified best place for data enthusiasts to work
            for!
          </p>

          <a href="#exploreOpportunities">
            <button className="button-wrapper">View Open Positions</button>
          </a>
        </div>
        <div className="grid-item2"></div>
      </div>
      <img
        loading="lazy"
        className="absolute-image"
        src={roboImage}
        alt="tech"
      />
    </div>
  );
}

export default Section1;
