import React from "react";
import linkedinImg from "../assets/img/linkedin.png";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  const menu = [
    {
      name: "Privacy Policy",
      link: "/privacyPolicy",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Career",
      link: "/career",
    },
    {
      name: "Customer Success Stories",
      link: "/customer-success-stories",
    },
    {
      name: "Contact Us",
      link: "/contact",
    },
  ];
  return (
    <>
      <div style={{ position: "relative", zIndex: "1" }} className="footer">
        <div className="footerMenu">
          {menu.map((menu, index) => {
            return (
              <span key={index}>
                <a href={menu.link}> {menu.name}</a>
              </span>
            );
          })}
        </div>

        <div
          className="linkedinCss"
          style={{
            position: "absolute",
            zIndex: "999",
            top: "15px",
            bottom: "0px",
            left: "40px",
          }}
        >
          <a href="https://www.linkedin.com/company/aays-analytics/">
            <div style={{ width: "50px", height: "50px" }}>
              <img
                loading="lazy"
                style={{ width: "100%" }}
                src={linkedinImg}
                alt="Linkedin"
              />
            </div>
          </a>
        </div>
      </div>

      <div className="copyRights">
        &copy; {currentYear} Aays. All Rights Reserved
      </div>
    </>
  );
};

export default Footer;
