import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/ind_blogs_Apache_Spark.jpg";
import apache_img from "../../assets/img/apache_img.png";
import blogs_15_body_img from "../../assets/img/blogs_15_body_img.png";
import { Helmet } from "react-helmet";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

function ApacheSparkPerformanceOptimization() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  useEffect(() => {

      document.title = "Apache Spark | Performance Optimization";
      document.getElementsByTagName("META")[3].content =
        "Apache Spark assists businesses in resolving data processing issues due to its quick data-processing capabilities. Here are key performance optimization strategies. ";
  })
      
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content="Apache Spark" />
      </Helmet>
      <Navbar />
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "white" }}>/</div>
                    )}
                  </div>
                ))}
              </div>

            <h1 className="main-heading">
              {" "}
              Performance Optimization for Apache Spark
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
                className="heading-content"
              >
                Apache Spark assists businesses in resolving data processing
                issues due to its quick data-processing capabilities. Any data
                processing program's performance is a crucial factor to consider
                while it's being developed. The following are the most important
                performance optimization strategies in Apache Spark.
              </p>

              <p className="heading-content">
                Shuffling - Key Performance Tuning Method
              </p>

              <p className="heading-content">
                Data is sent across distinct nodes in a spark cluster in the
                case of spark. The graph below depicts the shuffling process in
                Spark. Data is shuffled between the left-hand processing nodes
                and the right-hand processing nodes.
              </p>

              <p className="heading-content">Shuffling process in Spark</p>

              <p className="heading-content">
                <img src={apache_img} alt="apache_img" />
              </p>

              <h1 className="heading">Cost associated with Shuffling</h1>

              <p className="heading-content">
                The data sent during the shuffling process is transferred over
                the network, which is why shuffling is costly. Large data
                transfers over the network are always going to be costly in
                terms of system performance.
              </p>

              <h1 className="heading">Key points</h1>
              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>{" "}
                Shuffling of data across different nodes on a spark cluster is
                expensive in terms of performance.
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>
                Depending on the fields on which the aggregate operations are
                carried out on a cluster, the data shuffling can be reduced.
              </p>

              <h1 className="heading">
                Partitioning for Optimized Performance
              </h1>

              <p className="heading-content">
                Partitioning a spark dataframe appropriately can help you go a
                long way in optimizing the performance. For instance, consider a
                group by operation in a spark dataframe so as to get the sum of
                a field for each group. If there are further operations on the
                same group where you need to find out various other parameters
                for this group, then partitioning the entire dataframe based on
                this key will be a great performance optimization step. This
                ensures that the data that belongs to the same group will always
                go to the same node. This reduces the need for shuffling as data
                is already segregated as we want.
              </p>

              <p className="heading-content">
                The graph below depicts how partitioning on a spark dataframe
                works.
              </p>

              <p className="heading-content">
                <img src={blogs_15_body_img} alt="apache_img" />
              </p>

              <p className="heading-content">
                So, partitioning is a process by which data is segregated into
                small chunks on the basis of a certain field. As a result, the
                data belonging to the same group segregated this way always goes
                to the same location or partition. Since the data goes to the
                same location for the records with the same field value, the
                shuffling is reduced. For example, if the employees are
                segregated based on the department, each employee record
                belonging to the same department goes to the same partition. Any
                operations on the employee records grouped by department will
                thus avoid any data shuffling between nodes as the data is
                already segregated and is ready for the operation.
              </p>

              <h1 className="heading">
                Key Points related to Shuffling and Partitioning
              </h1>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>
                Repartitioning data on a spark dataframe involves shuffling.
                Partitioning is an operation where the data with similar key
                values needs to be grouped together which requires transfer of
                data across different nodes.
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>
                Join operation combines 2 or more data sets on the basis of a
                key. This often leads in values getting transferred across the
                nodes so as to aggregate data across different nodes. So
                effectively a join operation leads to shuffling.
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>
                Effectively any operations based by keys can result in a
                shuffling.
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>
                Contrary to the above, mapping operation doesn’t require data to
                be transferred across the nodes. Similar are the cases with
                operations like the filter and the unions. These operations
                specifically deal with the data transformations in each of the
                individual nodes and hence they don’t add to the shuffling.
              </p>

              <p className="heading-content">
                <i
                  style={{ color: "green" }}
                  class="fa fa-check"
                  aria-hidden="true"
                ></i>
                It’s always handy to do the filtering operations on a dataframe
                beforehand so that the operations will be on this filtered
                smaller dataset.
              </p>

              <h1 className="heading">Lazy Execution in Spark</h1>

              <p className="heading-content">
                The execution of operations in spark is in the lazy mode. For
                example, the code for a filter operation in spark dataframe
                doesn’t get executed at the same point where the code is
                written. Instead, it maintains some information about the order
                in which these steps will be executed which are called the DAGs.
                Effectively if the filtered result is going to be used by
                several processes, the calculation of the final filter dataframe
                happens in each of these cases.
              </p>

              <p className="heading-content">
                To explain this more clearly, consider the below code. This is a
                looped operation in which for each execution of the loop a
                computation is done on a dataframe called the filtered_df.
                Assume that the filtere_df is a result of the filtering
                operation from another dataframe called inbound_df. Every time
                the below operation is called on the filtered_df, the filtering
                operation is performed on the inbound_df as the spark does the
                lazy evaluation.
              </p>

              <p
                className="heading-content"
                style={{
                  width: "48%",
                  backgroundColor: "#D3D3D3",
                  padding: "1rem",
                  borderRadius: "6px",
                  fontWeight: 700,
                }}
              >
                for obj in list_objects:
                <br />
                compute_df = compute_dataframe(filtered_df,obj) percentage_df =
                calculate_percentage(compute_df) export_as_csv(percentage_df)
              </p>

              <h1 className="heading">Caching in Spark</h1>

              <p className="heading-content">
                To avoid the recomputation of the data frame as shown above,
                every time an operation is called, a technique called caching is
                employed. The idea is to store the dataframe on which the
                computations are called in a cache. So, instead of recomputing
                the data frame on the invocation of an operation, the dataframe
                that is previously stored in the cache is retrieved and
                subsequent operations are invoked
              </p>

              <h1 className="heading">Conclusion</h1>

              <p className="heading-content">
                As it can be seen from the examples above, Apache Spark is an
                excellent tool for processing massive amounts of data really
                quickly. However, when it comes to use cases involving
                large-scale data processing, it's important to remember its best
                practices. The things mentioned above are some of the most
                crucial suggestions for maximizing Apache Spark's performance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default ApacheSparkPerformanceOptimization;
