import { Helmet } from "react-helmet";
import { useState } from "react";
import $ from "jquery";
import Navbar from "../../../nav/NavBar";
import "./newBlog.scss";
import KeyPoints from "./Keypoints";
import { KeyPointDataEngineeringData } from "./KeyPointData";
import aaysInTopDataEngineering from "../../../assets/img/genai/Image.jpg";
import badge from "../../../assets/img/genai/badge - Top Data Engineering Service Providers-Leaders 2.png";
import logo from "../../../assets/img/logo.png";
import { useMediaQuery, useTheme } from "@mui/material/";
import Footer from "../../../nav/Footer";
import { useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const DataEngineerLeader = () => {
  const [isloading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = "Aays | Leader in Data Engineering AIM Report";
    document.getElementsByTagName("META")[3].content =
      "Aays recognized as a Leader in PeMa Quadrant 2024 for Data Engineering Services. Explore our AI-driven solutions, skilled data engineers, and market-leading innovations";
  });

  const rejectList = ["effem.com"];

  const downloadFinanceController = async () => {
    console.log("download function called");
    try {
      const response = await fetch(
        "https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/Top-Generative-AI-Service-Providers-2023.pdf"
      );

      console.log(response, "res");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      console.log(url, "url");
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute(
        "download",
        `Top-Generative-AI-Service-Providers-2023-${new Date().getTime()}.pdf`
      );

      document.body.appendChild(link);
      console.log(link, "link");
      link.click();
    } catch (error) {
      console.error("Error downloading finance controller:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    var URL =
      "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";

    var name = $("#fullName").val();
    var phone = "No phone number";
    var email = $("#email").val();
    var desg = $("#designation").val();
    var message = "";
    var company = $("#companyName").val();
    var splitArray = email.split("@");
    if (rejectList.indexOf(splitArray[1]) >= 0) {
      message = "View market modelling case study restricted";
    } else {
      // Remove ".html" extension

      message = "View Market Modelling case study";
    }

    var contactUsData = {
      name: name,
      phone: phone,
      email: email,
      designation: desg,
      message: message,
      company: company,
    };

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(contactUsData),

      success: function () {
        console.log("successfully submittedddd");
        // clear form and show a success message
        setisLoading(false);

        if (rejectList.indexOf(splitArray[1]) >= 0) {
          console.log("rejected");
          setisError(true);
          setTimeout(() => {
            setisError(false);
          }, 5000);
          return false;
        } else {
          console.log("view");
          downloadFinanceController();
        }
      },
      error: function () {
        setisError(true);
        setisLoading(false);

        setTimeout(() => {
          setisError(false);
        }, 5000);
      },
    });
  };

  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];

  return (
    <>
      <div id="homePage">
        <Helmet>
          <meta
            name="keywords"
            content="Aays Data Engineering, PeMa Quadrant 2024 Leader, Data Engineering Services, AI Data Engineering, Skilled Data Engineers, Market Leader in Data Engineering"
          />
        </Helmet>
        <Navbar />
        <div className="newBlog-body">
          <div className="newBlog-margin">
            <div className="newBlog-main">
              <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "white" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="firstSection">
                <div className="main">
                  <h1 className="heading">
                    Aays Recognized as a 'Leader' in Top Data Engineering
                    Service Providers PeMa Quadrant 2024
                  </h1>
                  <div className="date-read-container">
                    <div className="date-container">
                      <div className="date"> May 26, 2024 </div>
                      <div className="read">
                        <AccessTimeIcon
                          style={{
                            fontSize: "14px",
                          }}
                        />{" "}
                        <span> 2 min read</span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="box-form">
                    <div className="left">
                      <div className="badge">
                        <img src={badge} alt="badge" />
                        <div className="content">
                          Top Data Engineering Service Providers PeMa 2024
                        </div>
                      </div>
                    </div>
                    {!isMobile && (
                      <div className="right">
                        <div className="genai-form">
                          <div className="aays-logo">
                            <img src={logo} alt="aays-logo" />
                          </div>
                          <form className="form" onSubmit={handleSubmit}>
                            <input
                              placeholder="Full Name"
                              type="text"
                              name="fullName"
                              id="fullName"
                              required
                            />
                            <input
                              placeholder="Email Address"
                              type="email"
                              name="email"
                              id="email"
                              required
                            />
                            <div className="same-line">
                              <input
                                placeholder="Company Name"
                                type="text"
                                name="companyName"
                                id="companyName"
                                required
                              />
                              <input
                                placeholder="Designation"
                                type="text"
                                name="designation"
                                id="designation"
                                required
                              />
                            </div>

                            <div className="button">
                              <button>Download Report</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="content1">
                    <div className="text1">
                      In today's tech landscape, while AI models and platforms
                      are becoming commodities, data remains the crucial
                      differentiator for organizations aiming to develop winning
                      AI strategies. As AI models grow in size and complexity,
                      their demand for high-quality data increases. Visionary AI
                      leaders understand this and are investing heavily in data
                      collection and preparation to meet the scale and quality
                      needs of advanced AI systems. Data engineering forms the
                      foundation of this endeavour, providing the essential
                      infrastructure to fuel AI.
                    </div>
                    <div>
                      At Aays, we have always prioritized first-principle
                      thinking, making substantial investments in our data
                      engineering capabilities. This ensures that our customers
                      and partners can embark on their AI journeys with a
                      distinct advantage.
                    </div>

                    <div className="text1" style={{ paddingTop: "1rem" }}>
                      We are proud to announce that we have been placed in the
                      Leaders quadrant of the AIM Research PeMa Quadrant. This
                      prestigious recognition reflects our high standards and
                      commitment to innovation, quality, and excellence in data
                      engineering. Our strong market presence, consistent
                      growth, and exceptional teams have set a benchmark in the
                      industry, delivering top-tier services using cutting-edge
                      technologies.
                    </div>
                  </div>
                </div>
              </div>

              <div className="secondSection">
                <div className="main">
                  <div className="heading">
                    The Data Engineering Service Providers{" "}
                    <span>AIM PeMa Quadrant</span>
                  </div>
                  <div className="container">
                    <div
                      className="left"
                      style={{
                        width: "60%",
                      }}
                    >
                      <div className="content">
                        <p className="content1">
                          AIM Research’s Penetration and Maturity (PeMa)
                          Quadrant for Data Engineering Service Providers—a
                          reliable industry standard to evaluate vendor
                          competencies—makes an attempt to aid businesses in
                          choosing the most suitable Data Engineering service
                          provider aligned to their business needs. The report
                          aims to empower decision-makers with the knowledge
                          required to select the right data engineering service
                          provider for their unique needs. Through an
                          exploration of market dynamics and vendor profiles, we
                          provide a comprehensive map for navigating the data
                          engineering landscape, ensuring that organizations can
                          harness the full potential of data engineering to
                          transform their operations and stay competitive in the
                          digital age.
                        </p>
                        <p className="content2">
                          This section shows the different quadrants and the
                          positions occupied by participating vendors in the
                          quadrants along with their penetration and maturity
                          index.
                        </p>
                      </div>
                    </div>
                    <div className="right-img" style={{ width: "40%" }}>
                      <img src={aaysInTopDataEngineering} alt="aays" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="thirdSection">
                <div className="main">
                  <div className="heading">
                    Aays’ Key Competencies and Differentiators as highlighted in
                    the report
                  </div>
                  {/* <div className="content">
                    Different factors are highlighted within the report that
                    underscore Aays' competencies and key differentiators. Here
                    ‘Aays’ is referred to as the ‘vendor’.
                  </div> */}
                  <div className="text">Snippet from the report:</div>

                  <div className="points">
                    {KeyPointDataEngineeringData.map((data) => (
                      <KeyPoints
                        id={data.id}
                        title={data.title}
                        description={data.description}
                        image={data.image}
                      />
                    ))}
                  </div>
                  <div className="lastPoint">
                    The report was originally published in{" "}
                    <span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://aimresearch.co/penetration-maturity-pema-matrix/top-data-engineering-service-providers-pema-quadrant-2024"
                      >
                        AIM Research
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              {isMobile && (
                <div className="right">
                  <div className="genai-form">
                    <div className="aays-logo">
                      <img src={logo} alt="aays-logo" />
                    </div>
                    <form className="form" onSubmit={handleSubmit}>
                      <input
                        placeholder="Full Name"
                        type="text"
                        name="fullName"
                        id="fullName"
                        required
                      />
                      <input
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        id="email"
                        required
                      />
                      <div className="same-line">
                        <input
                          placeholder="Company Name"
                          type="text"
                          name="companyName"
                          id="companyName"
                          required
                        />
                        <input
                          placeholder="Designation"
                          type="text"
                          name="designation"
                          id="designation"
                          required
                        />
                      </div>

                      <div className="button">
                        <button>Download Report</button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DataEngineerLeader;
