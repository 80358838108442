import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiMiddleImage from "../../assets/img/Top Gen AI Service Providers.png";

import GenAiBanner from "../../assets/img/Aays - Gen AI seasoned vendor.jpg";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
function GenAI() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  useEffect(() => {
  document.title = "Gen AI Seasoned Vendor";
  document.getElementsByTagName("META")[3].content =
    "Aays recognized as a 'Gen AI Seasoned Vendor'! AIM Research's came up with a report on PeMa Quadrant does vendor comparisons for Gen AI and names Aays as a seasoned vendor. ";

  })
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];
  return (
    <div id="homePage" className="blogsPage">
      <Helmet>
        <meta name="keywords" content="Gen AI" />
      </Helmet>

      <Navbar />
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "white" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
            <h1 className="main-heading">
              {" "}
              Aays Recognized as 'Seasoned Vendor' in Gen AI Report by AIM
              Research
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
                className="heading-content"
              >
                We're thrilled to announce our inclusion in the "Seasoned
                Vendors" quadrant of Gen AI report by AIM Research.
              </p>

              <p className="heading-content">
                This recognition comes as validation of our dedication to
                pushing boundaries in innovation, ensuring our clients'
                satisfaction, and delivering technical expertise in the realm
                of analytics and Gen AI.
              </p>

              <h1 className="main-heading">
                Understanding the AIM PeMa Quadrant
              </h1>

              <p className="heading-content">
                The PeMa Quadrant provides enterprises with a comprehensive view
                of the market, enabling them to compare and contrast the
                capabilities of various vendors. This is AIMResearch’s tool
                designed to help businesses navigate this complex landscape. By
                evaluating service providers across various dimensions, the
                Quadrant assists in making informed decisions, ensuring that
                businesses partner with providers that align with their unique
                requirements.
              </p>

              <div className="middle-image" style={{}}>
                <img src={GenAiMiddleImage} alt="Gen Ai Service Provider" />
                <p className="heading-content">
                  <i
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Read the complete report
                    </p>
                    <a
                      style={{
                        fontSize: "15px",
                        marginLeft: "5px",
                        color: "#E67D1C",
                        fontWeight: "700",
                      }}
                      href="https://aimresearch.co/2023/11/16/top-generative-ai-service-providers-pema-quadrant-2023/"
                    >
                      here
                    </a>
                    <p>.</p>
                  </i>
                </p>
              </div>

              <h1 className="heading">
                {" "}
                Aays’ Key Competencies and Differentiators as highlighted in the
                report
              </h1>
              <p className="heading-content">
                Different factors are highlighted within the report that
                underscore Aays' competencies and key differentiators. Here
                ‘Aays’ is referred to as the ‘vendor’. <br /> <br /> Snippet
                from the report:
              </p>

              <h1 className="sub-heading">Integration with Broader Services</h1>
              <p className="heading-content">
                The vendor's approach to integrating genAI as a component of
                their broader consulting and analytics services allows them to
                tap into a wider client base that seeks holistic solutions. This
                strategy positions them favorably for market penetration as they
                can cater to clients looking for comprehensive AI-driven
                consulting.
              </p>

              <h1 className="sub-heading">Diverse Skill Development</h1>
              <p className="heading-content">
                The vendor's efforts in hiring dedicated roles, reskilling
                existing associates, and establishing a genAI proposition team
                indicate a proactive approach to building a skilled workforce
                capable of effectively promoting and delivering genAI solutions
                to clients.
              </p>

              <h1 className="sub-heading">
                Effective Handling of Complex Use Cases
              </h1>
              <p className="heading-content">
                The vendor's ability to build genAI capabilities for critical
                functional areas like finance, supply chain, etc. addressing
                complex needs of larger enterprise clients, demonstrates a high
                level of technical maturity.
              </p>

              <h1 className="sub-heading">Structured Data Integration</h1>
              <p className="heading-content">
                The commitment to seamlessly working with diverse data formats
                positions them as a reliable partner for clients with varied
                data requirements.
              </p>

              <h1 className="sub-heading">Mitigating Challenges and Bias</h1>
              <p className="heading-content">
                The vendor's approach to treating GenAI as a reasoning engine
                rather than a fact engine, breaking down complex tasks into
                smaller components, and implementing multi-layer filtering to
                reduce biases and hallucination issues demonstrates a deep
                understanding of the technology's limitations and a mature
                approach to managing these challenges.
              </p>

              <h1 className="sub-heading">
                Cost-Effective Infrastructure Management
              </h1>
              <p className="heading-content">
                The vendor's approach to treating GenAI as a reasoning engine
                rather than a fact engine, breaking down complex tasks into
                smaller components, and implementing multi-layer filtering to
                reduce biases and hallucination issues demonstrates a deep
                understanding of the technology's limitations and a mature
                approach to managing these challenges.
              </p>

              <h1 className="sub-heading">
                Transition from POCs to Scaling Engagements
              </h1>
              <p className="heading-content">
                The impressive track record of transitioning POCs into scaling
                engagements indicates their ability to showcase the value of
                genAI to clients, which is a key driver for market penetration
                as satisfied clients are likely to expand their usage of the
                technology
              </p>

              <h1 className="sub-heading">Significant R&D Investment</h1>
              <p className="heading-content">
                This demonstrates a strong commitment to advancing genAI
                capabilities. This investment can result in the development of
                cutting-edge solutions that attract a broader customer base.
              </p>

              <h1 className="sub-heading">Strategic Partnerships</h1>
              <p className="heading-content">
                Strong collaborations with industry giants and partnerships with
                AI research companies provide access to a wide network of
                potential clients and enhance the vendor's credibility,
                facilitating market penetration.
              </p>

              <p
                className="heading-content"
                style={{
                  fontSize: " 20px",
                  paddingTop: "2rem",
                  fontStyle: "italic",
                }}
              >
                The report was originally published in
                <a
                  style={{
                    color: "#E67D1C",
                    display: "inline",
                    paddingLeft: "10px",
                    fontWeight: "700",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://aimresearch.co/2023/11/16/top-generative-ai-service-providers-pema-quadrant-2023/"
                >
                  Gen AI
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default GenAI;
